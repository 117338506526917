
import { useUserInfoStore } from "~/stores/userInfoStore"
export default () => {
    const userInfoStore = useUserInfoStore()
    
    try{
        const isXiehui = userInfoStore.info.roles.find(x=>{
            return x.name == "协会"
        })
        if (isXiehui){
            return "协会"
        }
        return "酒店"
    }catch(e){
        return "酒店"
    }
}